import RestaurantDataSource from '../data/restaurantDataSource';
import {restaurantItem, skeletonRestaurantItem} from '../templates';

const Home = {
  async render() {
    return `
      <section id="welcome-block" class="article-header">
        <picture>
          <source
            media="(max-width: 480px)"
            srcset="./images/hero-image_2-small.webp">
          <source
            media="(max-width: 768px)"
            srcset="./images/hero-image_2-medium.webp">
          <source
            media="(min-width: 769px)"
            srcset="./images/hero-image_2-large.webp">
          <source
            media="(max-width: 480px)"
            srcset="./images/hero-image_2-small.jpg">
          <source
            media="(max-width: 768px)"
            srcset="./images/hero-image_2-medium.jpg">
          <img
            src="./images/hero-image_2-large.jpg"
               alt="">
        </picture>
        <div>
          <h2>Eat<span>Zones</span></h2>
          <p>The best portal to find best restaurants around the world</p>
        </div>
      </section>
      <section id="resto-list">
        <header class="section-header">
          <h3>Explore Restaurant</h3>
          <div></div>
        </header>
        <div id="resto-list-items">
          ${
  Array.from({length: 6}, () => 0)
      .map(() => skeletonRestaurantItem()).join('')
}
        </div>
        <div id="on-error"></div>
      </section>
    `;
  },

  async afterRender() {
    const hasData = await RestaurantDataSource.allRestaurants();
    const restaurantsContainer = document.querySelector('#resto-list-items');
    if (!hasData.error) {
      const {restaurants} = hasData;
      restaurantsContainer.innerHTML = '';
      restaurants.forEach((restaurant) => {
        restaurantsContainer.innerHTML += restaurantItem(restaurant);
      });
    } else {
      document.querySelector('#on-error').innerHTML = `<p class="text-center">
        <br/><br/><br/><br/><br/><br/>
        <i
          class="fa fa-exclamation-triangle"
        ></i> ${hasData.message}</p>
        <br/><br/>`;
    }
  },
};

export default Home;
