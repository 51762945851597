import FavoriteRestaurantIdb from '../../data/favoriteRestaurantData-Idb';
import FavoriteRestaurantSearchView from './favoriteRestaurantSearchView';
import FavoriteRestaurantShowPresenter from './favoriteRestaurantShowPresenter';
import FavoriteRestaurantSearchPresenter
  from './favoriteRestaurantSearchPresenter';

const view = new FavoriteRestaurantSearchView();

const Favorite = {
  async render() {
    return view.getTemplate();
  },

  async afterRender() {
    new FavoriteRestaurantShowPresenter({
      view,
      favoriteRestaurants: FavoriteRestaurantIdb,
    });
    new FavoriteRestaurantSearchPresenter({
      view,
      favoriteRestaurants: FavoriteRestaurantIdb,
    });
  },
};

export default Favorite;
